<template>
  <section class="backgroundColor">
    <v-row>
      <v-col cols="12">
        <p class="text-h5">
          {{ participacion.proveedor }}
        </p>
      </v-col>
      <v-col cols="12">
        <v-row >
          <v-col cols="12" md="1" sm="2" lg="1">
            <p class="text-h4">
              {{
                Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
                }).format(participacion.precio_unitario)
              }}
            </p>
            <p class="text-subtitle-2 d-flex justify-center">Precio Unitario</p>
          </v-col>
          <v-col cols="12" md="1" sm="2" lg="1">
            <p class="text-h4 d-flex justify-center">{{ participacion.cantidad }}</p>
            <p class="text-subtitle-2 d-flex justify-center">Cantidad</p>
          </v-col>
          <v-col cols="12" md="2" sm="4"  lg="1" >
            <p class="text-h4">
              {{
                Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
                }).format(participacion.total)
              }}
            </p>
            <p class="text-subtitle-2 d-flex justify-center">Total ofertas</p>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12">
        <v-card>
          <v-row class="mx-5">
            <v-col cols="12" sm="5">
              <v-row>
                <v-col cols="12">
                  <span>Nombre del proveedor</span>
                  <p class="font-weight-black">
                    {{ participacion.proveedor }}
                  </p>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <span>OBS</span>
                  <p class="font-weight-black">
                    {{ participacion.Obs.nombre }}
                  </p>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <span>Articulo</span>
                  <p class="font-weight-black">{{ participacion.articulo }}</p>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <span>Unidad de medida</span>
                  <p class="font-weight-black">{{ participacion.unidad_medida }}</p>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <span>Especificación</span>
                  <p class="font-weight-black">
                    {{ participacion.especificacion }}
                  </p>
                </v-col>
              </v-row>
            </v-col>

            <v-col cols="12" sm="7">
              <v-card elevation="5">
                <v-card-text>
                  <v-card-title style="color: #404a7a"
                    >Formulario de oferta</v-card-title
                  >
                  <v-row>
                    <v-col cols="8">
                      <v-text-field
                        append-icon="mdi-paperclip"
                        readonly
                        label="Oferta"
                        outlined
                        :value="
                          participacion.nombre_documento
                        "
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="6">
                      <v-text-field
                        readonly
                        prefix="$"
                        outlined
                        label="Precio unitario *"
                        :value="
                          participacion.precio_unitario
                        "
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        outlined
                        label="Cantidad"
                        :value="participacion.cantidad"
                        min="0"
                        type="number"
                        readonly
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="5">
                      <v-text-field
                        readonly
                        prefix="$"
                        outlined
                        :value="participacion.total"
                        label="Total oferta"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
        </v-row>
        </v-card>
      </v-col>
      <v-col class="mt-5" cols="12">
        <v-form ref="form">
          <v-row>
            <v-col cols="12" sm="4" md="2">
              <v-text-field :rules="enterosRules" @keypress="isNumber($event)" v-model="form.evaluacion_financiera"
                outlined label="Evaluacion financiera"></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" md="2">
              <v-text-field :rules="enterosRules" @keypress="isNumber($event)" v-model="form.evaluacion_tecnica"
                outlined label="Evaluacion técnica"></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="4" md="2">
              <v-checkbox v-model="form.evaluacion_legal" class="ml-2" label="¿Cumple evaluación legal?">
              </v-checkbox>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-btn outlined :block="btnBlock" color="secondary" class="mr-3" @click="$router.go(-1)">Volver</v-btn>
        <v-btn @click="actualizarProveedor" color="secondary" :block="btnBlock" dark
          :class="$vuetify.breakpoint.xs ? 'mt-3' : false">
          Guardar
        </v-btn>
      </v-col>
    </v-row>
  </section>
</template>

<script>
export default {
  name: "OfertaEvaluacion",
  data() {
    return {
      form: {
        evaluacion_legal: false,
        evaluacion_financiera: null,
        evaluacion_tecnica: null,
      },
      participacion: {},
      enterosRules: [
        (v) => !!v || v !== "0" || "El campo es requerido.",
        (v) => Number.isInteger(+v) || "El campo debe ser de tipo entero.",
        (v) => +v >= 0 || "No debe ser menor que cero.",
        (v) => +v <= 10 || "No debe ser mayor que diez.",
      ],
    };
  },

  computed: {
    btnBlock() {
      return !!this.$vuetify.breakpoint.xs;
    },
    getIdParticipacion() {
      return +this.$route.params.id_proveedor_participacion_obs || 0;
    },
  },

  created() {
    this.obtenerParticipacion();
  },

  methods: {
    async obtenerParticipacion() {
      const response =
        await this.services.Paac.obtenerProveedorParticipacionObs(
          this.getIdParticipacion
        );
      if (response.status === 200) {
        this.participacion = response.data.proveedor_participacion;
        this.form = {
          evaluacion_legal: this.participacion.evaluacion_legal,
          evaluacion_financiera: this.participacion.evaluacion_financiera,
          evaluacion_tecnica: this.participacion.evaluacion_tecnica,
        };
      }
    },
    async actualizarProveedor() {
      if (this.$refs.form.validate()) {
        const response = await this.services.Paac.actualizarSubProcesoObs(this.getIdParticipacion, {
          evaluacion_tecnica: +this.form.evaluacion_tecnica || 0,
          evaluacion_financiera: +this.form.evaluacion_financiera || 0,
          evaluacion_legal: [true, false].includes(
            this.form.evaluacion_legal
          )
            ? this.form.evaluacion_legal
            : false,
        });
        if(response.status === 200){
          this.temporalAlert({
            show: true,
            message: 'Revisión realizada con éxito',
            type: 'success'
          })
          this.$router.go(-1)
        }
      }
    },

    isNumber(evt) {
      const { keyCode } = evt;

      if (keyCode < 48 || keyCode > 57) {
        return evt.preventDefault();
      }
    },
  },
};
</script>

<style scoped>
.backgroundColor {
  background-color: #f4f7fd;
}
</style>
